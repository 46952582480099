<template>
  <div class="suchdoge">

      <img :key=this.url alt='A Doge'
           :src=this.url
           :width=this.width
           :height=this.height
      />

  </div>
</template>

<script>
export default {
  name: 'Randoge',
  props: {
    url: String,
    width: Number,
    height: Number
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.suchdoge{
    height: 100%;
    margin: 0;
    padding:0;
    text-align: center;
}

@media (min-width: 768px) {
    .suchdoge img{
        vertical-align: middle;
        height:100%;
    }
}


@media (max-width: 767px) {
    .suchdoge img{
        vertical-align: middle;
        width:100%;
        margin-top: 50%;
        position: relative;
    }
}


.dogeimg-enter-active {
    transition: all 250ms;
}
.dogeimg-leave-active {
    transition: all 250ms;
}

.dogeimg-enter, .dogetrans-leave-to{
    opacity: 0
}

</style>
