<template>
        <div
                class="suchprice" :key="this.amount">
        <span :style=this.happStyle()
              :class="this.getClasses">{{this.amount}}</span>
        </div>

</template>

<script>
export default {
  name: 'DogePrice',
  props: {
    amount: Number,
    time: Date,
    happ: Boolean
  },
  computed:{
    getClasses: function() {
      return `dogeprice`
    }
  },
  methods:{
    happStyle: function () {
      if (this.happ) return 'color: lightgreen'
      else return 'color: red'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.suchprice{
    height: 100vh;
    position: absolute;
    padding:0;
    margin:0;
    width: 100vw;
    top: 0;
    text-align: center;
    vertical-align: middle;
    font-size: xxx-large;
    font-family: "Nothing You Could Do", cursive;
}

.dogeprice{
    background-color: rgba(0,0,0,.5);
    text-shadow: 0 0 5px white;
    padding: 1%;
    border: 1px black solid;
    vertical-align: middle;
    line-height: 100vh;
    transition: all;
}
</style>
