import axios from 'axios'
export class api {
  async getPrice(){
    let px = await axios.get('https://api.kraken.com/0/public/Ticker?pair=XDGUSD')
    return parseFloat(px.data.result['XDGUSD'].a[0])
  }

  async getImages(){
    let result = await axios.get('https://dogeplanet.com/getfiles.php')
    return result;
  }
}

const api_instance = new api
export default api_instance
