-<template>
  <div class="dogesays">
<transition name="dogesay">
          <div
                :key=this.happ
                :style=this.happStyle()
                  class="saying">{{ this.getSaying() }}</div>
</transition>
  </div>
</template>

<script>
let doge= ['very', 'such', 'many', 'much']
let dogeSad= ['sad', 'concern', 'disappoint', 'unhapp', 'sorry', 'danger', 'red']
let dogeHapp= ['happ', 'wow', 'doge', 'happness', 'celebrate', 'green']
export default {
  name: 'DogeSays',
  props: {
   happ: Boolean
  },
  methods: {
    getSaying() {
      let dogeDex = Math.floor(doge.length * Math.random())

      let r = `${doge[dogeDex]} `
      if (this.happ){
        let wordDex = Math.floor(dogeHapp.length * Math.random())
        r+=dogeHapp[wordDex]+'!'
      }
      else{
        let wordDex = Math.floor(dogeSad.length * Math.random())
        r+= dogeSad[wordDex]+'.'
      }

      return r
    },
    happStyle() {
      if (this.happ){
        return 'text-shadow: 0 0 25px green;'
      }
      else{
        return 'text-shadow: 0 0 25px red;'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.dogesays{
    width: 100%;
    padding:0;
    text-align: center;
    font-family: 'Comic Neue', cursive;
    font-size: 3em;
    top:0;
}

.saying{
    position: absolute;
    color: white;
    top: 60vh;
    width: 100vw;
}
.dogesay-enter-active {
    transition: all 500ms;
}
.dogesay-leave-active {
    transition: all 500ms;
}

.dogesay-leave-to{
    transform: scale(5);
    opacity: 0;
}
</style>
