<template>
  <div id="app">
    <Randoge
    :url=this.image />
    <DogePrice
    :amount=this.price
    :happ="this.happ"
    />
    <DogeSays
            :happ="happ" />
  </div>
</template>

<script>
import Randoge from './components/Randoge'
import DogePrice from './components/DogePrice'
import DogeSays from './components/DogeSays'
import api from './api'

export default {
  name: 'App',
  components: {
    Randoge,
    DogePrice,
    DogeSays
  },
  data () {
    return {
      image: '',
      price: 0,
      imageList: [],
      happ: true
    }
  },
  created () {
    this.getPrice()
    api.getImages().then(
      (r) => {
      for(let i = 0; i < r.data.files.length; i++){
        this.imageList.push(r.data.files[i])
      }
    this.getAny()
    })
  },
  methods: {
    getPrice(){
      api.getPrice().then((r=>{
        if (r > this.price){
          this.happ = true
        }
        else if (r < this.price){
          this.happ  = false
        }
        this.price = r
        setTimeout(this.getPrice, 1000)
      }))
    },
    getAny() {
      let index = Math.floor(this.imageList.length * Math.random())
      this.image = `https://dogeplanet.com/images/${this.imageList[index].file}`
      setTimeout(this.getAny, 5000)
  }}
}

</script>

<style>
html{
    height: 100vh;
}

body{
    margin: 0;
    border: 0;
    cursor: url(https://dogeplanet.com/dogepointer.png), default !important;
}
body {
    height: 100%;
    width: 100%;
}

#app {
    overflow: hidden;
    height: 100%;
    background: black;
}


.green{
    color: green;
}

.red{
    color: red;
}
</style>
